import React from 'react'
import GallaryComponent from '../component/gallary/GallaryComponent'

const Gallary = () => {
  return (
    <div>
        <GallaryComponent />
    </div>
  )
}

export default Gallary